import type { ColDef, ICellRendererParams, IRichCellEditorParams } from '@ag-grid-community/core';
import type { NiceModalHandler } from '@ebay/nice-modal-react';

import type { TemplateAdditionalFields } from '@/store/api/hedgerApi/hedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import type { ColumnTable } from '@/components/hedger/manage/common/Table.tsx';
import type { EditTableModalProps } from '@/components/hedger/manage/recipients/details/EditTableModal.tsx';
import type { MultiEditTableModalProps } from '@/components/hedger/manage/recipients/details/MultiEditTableModal.tsx';
import type { TableModalProps } from '@/components/hedger/manage/recipients/details/ViewTableModal.tsx';
import type { EditAdditionalFieldsGrid } from '@/components/hedger/manage/recipients/EditRecipientGrid.tsx';
import type { IntlMessages } from '@/types/intl';

type ModalMode = 'Edit' | 'View';

function getTitleIdFromFieldName(name: string, mode: ModalMode): IntlMessages | undefined {
  switch (name) {
    case 'marketLetters':
      return `Hedger.Recipients.${mode}Modal.MarketLetters`;
    case 'hedgePortfolios':
      return `Hedger.Recipients.${mode}Modal.HedgePortfolios`;
    case 'accounts':
      return `Hedger.Recipients.${mode}Modal.Accounts`;
  }
}

export function getViewRecipientColumnTables(
  additionalFields: TemplateAdditionalFields[],
  modal: NiceModalHandler<TableModalProps>,
): ColumnTable<string>[] {
  return additionalFields.map(({ name, displayName, type }) => {
    const column: ColumnTable<string> = {
      field: name,
      headerName: displayName,
    };
    if (name === 'marketLetters' || name === 'hedgePortfolios' || name === 'accounts') {
      const titleId = getTitleIdFromFieldName(name, 'View');
      column.Component = ({ value }) => (
        <div className="align-items-center d-flex ">
          <Button
            size="sm"
            variant={'default'}
            onClick={() =>
              modal.show({
                name,
                data: value,
                titleId,
                tableType: name === 'hedgePortfolios' ? 'multiple' : 'single',
              })
            }
          >
            View details
          </Button>
        </div>
      );
    }

    return column;
  });
}

export function getRecipientAdditionalFieldsColDef(
  additionalFields: TemplateAdditionalFields[],
  showEditTableModal: (params: EditTableModalProps) => Promise<unknown>,
  showMultiEditTableModal: (params: MultiEditTableModalProps) => Promise<unknown>,
  updateAdditionalField: EditAdditionalFieldsGrid['updateAdditionalField'],
  mode: ModalMode,
): ColDef[] {
  return additionalFields.map(({ name, displayName, type, acceptedValues }) => {
    const colDef: ColDef = {
      field: name,
      headerName: displayName,
      editable: mode === 'Edit',
    };
    if (type === 'string' && acceptedValues !== undefined) {
      colDef.cellEditor = 'agRichSelectCellEditor';
      colDef.cellEditorParams = {
        values: acceptedValues,
        cellRenderer: (props: ICellRendererParams) => props.value,
        searchType: 'match',
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
        valueListMaxHeight: 450,
      } as IRichCellEditorParams;
    } else if (name === 'hedgePortfolios') {
      colDef.editable = false;
      colDef.valueGetter = value => value;
      colDef.cellRenderer = (params: ICellRendererParams) => {
        const fieldData = params.data[name];
        const titleId = getTitleIdFromFieldName(name, mode);
        const buttonTexts =
          name === 'hedgePortfolios'
            ? { add: 'Hedge Portfolio', delete: 'Portfolio' }
            : { add: 'Market', delete: 'Market' };

        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="px-3 py-1"
              size="md"
              discreet={mode === 'Edit'}
              variant={mode === 'Edit' ? 'info' : 'default'}
              onClick={() =>
                showMultiEditTableModal({
                  name,
                  data: fieldData,
                  titleId,
                  buttonTexts,
                  updateAdditionalField,
                })
              }
            >
              {mode === 'Edit' ? 'Edit' : 'View'} details
            </Button>
          </div>
        );
      };
    } else if (name === 'accounts' || name === 'marketLetters') {
      colDef.editable = false;
      colDef.valueGetter = value => value;
      colDef.cellRenderer = (params: ICellRendererParams) => {
        const fieldData = params.data[name];
        const titleId = getTitleIdFromFieldName(name, mode);

        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="px-3 py-1"
              size="md"
              discreet={mode === 'Edit'}
              variant={mode === 'Edit' ? 'info' : 'default'}
              onClick={() =>
                showEditTableModal({
                  name,
                  data: fieldData,
                  titleId,
                  updateAdditionalField,
                })
              }
            >
              {mode === 'Edit' ? 'Edit' : 'View'} details
            </Button>
          </div>
        );
      };
    }

    return colDef;
  });
}
