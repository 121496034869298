import type { ReactNode } from 'react';
import type {
  ColDef,
  GridOptions,
  RowClassParams,
  RowStyle,
  ValueFormatterParams,
} from '@ag-grid-community/core';

export function AgGridLite<T extends Record<string, any>>(tableProps: GridOptions<T>): JSX.Element {
  const { columnDefs, rowData, getRowStyle } = tableProps;

  const columns = columnDefs ?? [];
  const headers = columns.map(getHeader);
  const data = rowData ?? [];

  return (
    <div style={{ maxHeight: '200px', overflow: 'auto' }}>
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header} scope="col">
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row, index) => {
            const style = getStyle(getRowStyle, row);
            return (
              <tr style={{ ...style }} key={index}>
                {columns.map((column, index) => (
                  <td key={index}> {getRowValue(row, column)}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function getStyle<T>(
  getRowStyle: { (params: RowClassParams<T, any>): RowStyle | undefined } | undefined,
  row: T,
) {
  return getRowStyle !== undefined
    ? getRowStyle({ data: row } as RowClassParams<T, any>)
    : undefined;
}

function getHeader(column: ColDef) {
  return column.headerName ?? column.field;
}

function getRowValue<T extends Record<string, any>>(row: T, column: ColDef<T>): ReactNode {
  const { valueFormatter, field } = column;
  const value = field !== undefined ? row[field] : undefined;

  if (valueFormatter !== undefined && typeof valueFormatter === 'function') {
    return valueFormatter({ value } as ValueFormatterParams<T>);
  }

  if (value != null) {
    return value.toString();
  }

  console.warn(`Unhandled colDef ${column.headerName ?? column.field}`);
}
