import { useMemo } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { RowClassParams, RowStyle } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useRouter } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';
import { asSequence } from 'sequency';

import type { MarketRef } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { type AxisConfig, type Scenario } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { AgGridLite } from '@/components/hedger/manage/common/AgGridLite.tsx';
import { ViewHeader, type DataItem } from '@/components/hedger/manage/common/ViewHeader.tsx';
import {
  getMarketConfigColDef,
  useFormatHedgeAxis,
} from '@/components/hedger/manage/scenarios/components/hedgeAxis.ts';
import { useScenarioQueryData } from '@/components/hedger/manage/scenarios/components/useScenarioQueryData.tsx';
import { objectValues } from '@/utils/libs/entries.ts';

export interface MarketConfigRowData extends AxisConfig {
  rowId: string;
}

export function ViewScenarios() {
  const queryData = useScenarioQueryData();
  if (queryData.state === 'uninitialized') {
    return queryData.Component;
  }

  const Scenarios = asSequence(queryData.scenarios)
    .sortedBy(scenario => scenario.scenarioId)
    .map(scenario => (
      <ViewScenario
        scenario={scenario}
        key={scenario.scenarioId}
        recipients={queryData.recipients}
        markets={queryData.markets}
      />
    ))
    .toArray();

  return (
    <div className="d-flex flex-column mx-auto w-75 gap-3" style={{ minWidth: '60rem' }}>
      {Scenarios}
    </div>
  );
}

interface ViewScenarioFrameProps {
  scenario: Scenario;
  recipients: Recipient[];
  markets: MarketRef[];
}

function ViewScenario({ scenario, markets, recipients }: ViewScenarioFrameProps): JSX.Element {
  const { trackEvent } = useMatomo();
  const hasTeams = useAppSelector(userSlice.selectors.hasAnyTeam);
  const { navigate } = useRouter();
  const formatHedgeAxis = useFormatHedgeAxis();

  const marketConfigs = objectValues(scenario.axisConfigs);
  const currency = scenario.ordersMetricsCurrency;
  const columns = useMemo(
    () =>
      getMarketConfigColDef(
        'view',
        scenario.hedgeAxis,
        recipients,
        markets,
        currency,
        formatHedgeAxis,
      ),
    [scenario.hedgeAxis, recipients, markets, currency, formatHedgeAxis],
  );

  function onEditClick() {
    trackEvent({
      category: 'Hedger',
      action: 'Edit scenario',
      name: `Edited scenario ID : ${scenario.scenarioId}`,
    });
    return navigate({
      to: '/hedger/scenarios/edit/$scenarioId',
      params: { scenarioId: scenario.scenarioId.toString() },
    });
  }

  function onCloneClick() {
    trackEvent({
      category: 'Hedger',
      action: 'Clone scenario',
      name: `Cloned scenario ID : ${scenario.scenarioId}`,
    });
    return navigate({
      to: '/hedger/scenarios/create',
      search: { cloneId: scenario.scenarioId },
    });
  }

  const frameDataItems: DataItem[] = [
    {
      title: 'Owner team',
      data: scenario.team,
    },
    {
      title: 'Currency',
      data: scenario.ordersMetricsCurrency,
    },
    {
      title: 'Deactivated product type',
      data: scenario.deactivatedProductTypes.join(', '),
    },
    {
      title: 'Hedge Axis',
      data: scenario.hedgeAxis,
    },
  ];

  return (
    <div className="d-flex flex-column flex-grow-1 bg-lvl2 gap-3 p-3" data-e2e="scenario-frame">
      <div className="d-flex justify-content-between align-items-center">
        <h4>{scenario.name}</h4>
        <div className="d-flex gap-3">
          <Button size="lg" className="btn-icon-end" onClick={onCloneClick} disabled={!hasTeams}>
            <FormattedMessage id="Hedger.ScenariosModal.Clone" />
            <em className="icon">content_copy </em>
          </Button>
          <Button size="lg" className="btn-icon-end" onClick={onEditClick} disabled={!hasTeams}>
            <FormattedMessage id="Hedger.ScenariosModal.Edit" />
            <em className="icon">edit </em>
          </Button>
        </div>
      </div>
      <ViewHeader items={frameDataItems} />
      <AgGridLite columnDefs={columns} rowData={marketConfigs} getRowStyle={getRowStyle} />
    </div>
  );
}

function getRowStyle(params: RowClassParams<AxisConfig>): RowStyle | undefined {
  if (!params.data?.active) {
    return { opacity: '0.4' };
  }
}
