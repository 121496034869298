import { useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import deepEqual from 'fast-deep-equal';

import { useAppDispatch, useAppSelector, useAppStore } from '@/store/hooks.ts';
import {
  userPreferencesSlice,
  userPrefSchema,
  type CustomHierarchiesLabels,
  type FilterKind,
  type HedgerOrderDefaultEndTime,
  type HedgerOrderDefaultRelativeEndTime,
  type HedgerOrderDefaultRelativeStartTime,
  type HedgerOrderDefaultStartTime,
  type HedgerOrderDefaultTime,
  type NegativeNumberFormat,
  type NumberFormat,
  type UserPreferences,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { querySlice, type ExclusionMode } from '@/store/slices/query/querySlice.ts';
import { uiSlice } from '@/store/slices/ui/uiSlice.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import type { AppState } from '@/store/store.ts';
import { saveUserSessionService } from '@/web/session/sessionApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { MessageTooltip } from '@/components/common/bootstrap/MessageTooltip.tsx';
import { Tabs } from '@/components/common/bootstrap/Tabs.tsx';
import { useKey } from '@/components/common/hooks/useKey.ts';
import { ColorCodingTab } from '@/components/prefs/tabs/ColorRules/ColorCodingTab.tsx';
import { DataTab } from '@/components/prefs/tabs/DataTab.tsx';
import { DisplayDataTab } from '@/components/prefs/tabs/DisplayDataTab.tsx';
import { SELECTED_CUBE_SESSION_KEY } from '@/components/prefs/userPrefsStorage.ts';
import type { CurrencyValue } from '@/utils/libs/currency.ts';
import { setTheme, type Theme } from '@/utils/libs/theme.ts';
import { saveToStorage } from '@/utils/storage/storage.ts';

export function UserPrefs(): JSX.Element {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const cubeInstances = useAppSelector(state => state.ui.cubeInstances);
  const email = useAppSelector(userSlice.selectors.email);

  const ref = useRef(null);
  useKey('Escape', handleClose);

  function handleClose() {
    return dispatch(uiSlice.actions.setCurrentPageOverlay(null));
  }

  const [activeTab, setActiveTab] = useState<TabName>(defaultTab);

  const dataInitial: DataPrefs = useAppSelector(selectDataPrefs);
  const displayDataInitial: DisplayDataPrefs = useAppSelector(selectDisplayDataPrefs);

  const [displayDataPrefs, setDisplayDataPrefs] = useState<DisplayDataPrefs>(displayDataInitial);
  const [dataPrefs, setDataPrefs] = useState<DataPrefs>(dataInitial);

  async function saveChanges() {
    if (displayDataPrefs.theme !== displayDataInitial.theme) {
      setTheme(displayDataPrefs.theme);
      dispatch(uiSlice.actions.setTheme(displayDataPrefs.theme));
    }

    if (dataPrefs.exclusionMode !== dataInitial.exclusionMode) {
      dispatch(querySlice.actions.setExclusionMode(dataPrefs.exclusionMode));
    }

    if (
      displayDataPrefs.selectedCubeInstanceName !== displayDataInitial.selectedCubeInstanceName &&
      displayDataPrefs.selectedCubeInstanceName !== undefined
    ) {
      const instance = cubeInstances.find(
        cubeInstance => cubeInstance.cubeHostName === displayDataPrefs.selectedCubeInstanceName,
      );
      if (instance !== undefined) {
        // FIXME: This should allow switching ws without reloading but ag-grid does not like it
        // dispatch(
        //   querySlice.actions.setCubeInfos({
        //     cubeMode: instance.cubeMode,
        //     wsUrl: instance.webSocketUrl,
        //   }),
        // );
        saveToStorage(SELECTED_CUBE_SESSION_KEY, instance.cubeHostName);
      }
    }
    dispatch(
      userPreferencesSlice.actions.setPref(
        userPrefSchema.parse({ ...displayDataPrefs, ...dataPrefs }),
      ),
    );

    // TODO: should this be stored in querySlice instead of userPrefs slice ?
    const shouldReload =
      displayDataPrefs.currencyValue !== displayDataInitial.currencyValue ||
      displayDataPrefs.monitorExecutionPlan !== displayDataInitial.monitorExecutionPlan ||
      displayDataPrefs.negativeNumberFormat !== displayDataInitial.negativeNumberFormat ||
      displayDataPrefs.numberFormat !== displayDataInitial.numberFormat ||
      displayDataPrefs.flashCell !== displayDataInitial.flashCell ||
      displayDataPrefs.monitorExecutionPlan !== displayDataInitial.monitorExecutionPlan ||
      dataPrefs.filterKind !== dataInitial.filterKind ||
      displayDataPrefs.selectedCubeInstanceName !== displayDataInitial.selectedCubeInstanceName;

    await saveUserSessionService(
      email,
      'userPreferences',
      store.getState().userPreferences,
      userPrefSchema,
    );
    if (shouldReload) {
      window.location.reload();
    }
    handleClose();
  }

  const hasChanges =
    !deepEqual(displayDataInitial, displayDataPrefs) || !deepEqual(dataInitial, dataPrefs);

  useKey('Escape', handleClose);

  return (
    <div ref={ref} className="d-flex flex-column card p-4">
      <div className="flex-between">
        <div className="display-4">User preferences</div>
        <div className="d-flex justify-content-between gap-2">
          {hasChanges && <TopButtons onCancel={handleClose} onConfirm={saveChanges} />}

          <Button flat icon onClick={handleClose}>
            <em className="icon">close</em>
          </Button>
        </div>
      </div>
      <div className="px-1">
        <UserSummary />
      </div>

      <Tabs
        className="border-bottom mb-4"
        tabNames={tabNames}
        active={activeTab}
        onSelect={setActiveTab}
        getMessage={tabName => `UserPrefs.Tab.${tabName}`}
      />
      <ActiveTab
        activeTab={activeTab}
        dataPrefs={dataPrefs}
        setDataPrefs={setDataPrefs}
        displayDataPrefs={displayDataPrefs}
        setDisplayDataPrefs={setDisplayDataPrefs}
      />
    </div>
  );
}

export type DisplayDataPrefs = {
  numberFormat: NumberFormat;
  flashCell: boolean;
  currencyValue: CurrencyValue;
  negativeNumberFormat: NegativeNumberFormat;
  theme: Theme;
  monitorExecutionTiming: boolean;
  monitorExecutionPlan: boolean;
  selectedCubeInstanceName: string | undefined;
  topPanelWidgetLimit: number | undefined;
};

export type DataPrefs = {
  filterKind: FilterKind;
  hedgerOrderDefaultTime: HedgerOrderDefaultTime;
  hedgerOrderDefaultStartTime: HedgerOrderDefaultStartTime;
  hedgerOrderDefaultEndTime: HedgerOrderDefaultEndTime;
  hedgerOrderDefaultRelativeStartTime: HedgerOrderDefaultRelativeStartTime;
  hedgerOrderDefaultRelativeEndTime: HedgerOrderDefaultRelativeEndTime;
  customHierarchiesLabels: CustomHierarchiesLabels;
  exclusionMode: ExclusionMode;
};
const tabNames = ['Data', 'DisplayData', 'ColorCoding'] as const;
type TabName = (typeof tabNames)[number];

const defaultTab: TabName = 'DisplayData';

const selectExclusionMode = (state: AppState) => state.query.exclusionMode;

function selectUserPrefs(state: AppState): UserPreferences {
  return state.userPreferences;
}

const selectDataPrefs = createSelector(
  [selectUserPrefs, selectExclusionMode],
  (userPreferences, exclusionMode): DataPrefs => {
    return {
      filterKind: userPreferences.filterKind,
      hedgerOrderDefaultTime: userPreferences.hedgerOrderDefaultTime,
      hedgerOrderDefaultStartTime: userPreferences.hedgerOrderDefaultStartTime,
      hedgerOrderDefaultEndTime: userPreferences.hedgerOrderDefaultEndTime,
      hedgerOrderDefaultRelativeStartTime: userPreferences.hedgerOrderDefaultRelativeStartTime,
      hedgerOrderDefaultRelativeEndTime: userPreferences.hedgerOrderDefaultRelativeEndTime,
      customHierarchiesLabels: userPreferences.customHierarchiesLabels,
      exclusionMode,
    };
  },
);

const selectTheme = (state: AppState) => state.ui.theme;

const selectCubeInstance = (state: AppState) => state.ui.selectedCubeInstanceName;

const selectDisplayDataPrefs = createSelector(
  [selectUserPrefs, selectTheme, selectCubeInstance],
  (userPreferences, theme, cubeInstance): DisplayDataPrefs => {
    return {
      theme,
      selectedCubeInstanceName: cubeInstance,
      flashCell: userPreferences.flashCell,
      monitorExecutionPlan: userPreferences.monitorExecutionPlan,
      monitorExecutionTiming: userPreferences.monitorExecutionTiming,
      numberFormat: userPreferences.numberFormat,
      negativeNumberFormat: userPreferences.negativeNumberFormat,
      currencyValue: userPreferences.currencyValue,
      topPanelWidgetLimit: userPreferences.topPanelWidgetLimit,
    };
  },
);

function TopButtons(props: { onCancel: () => void; onConfirm: () => void }) {
  return (
    <>
      <Button flat variant="primary" onClick={props.onCancel}>
        Cancel
      </Button>
      <Button variant="info" onClick={props.onConfirm}>
        Save changes
      </Button>
    </>
  );
}

function ActiveTab({
  activeTab,
  dataPrefs,
  setDataPrefs,
  displayDataPrefs,
  setDisplayDataPrefs,
}: {
  activeTab: TabName;
  dataPrefs: DataPrefs;
  setDataPrefs: Dispatch<SetStateAction<DataPrefs>>;
  displayDataPrefs: DisplayDataPrefs;
  setDisplayDataPrefs: Dispatch<SetStateAction<DisplayDataPrefs>>;
}): JSX.Element {
  switch (activeTab) {
    case 'Data':
      return <DataTab dataPrefs={dataPrefs} setDataPrefs={setDataPrefs} />;
    case 'DisplayData':
      return <DisplayDataTab userPrefs={displayDataPrefs} setUserPrefs={setDisplayDataPrefs} />;
    case 'ColorCoding':
      return <ColorCodingTab />;
  }
}

function UserSummary() {
  const { teams, name } = useAppSelector(userSlice.selectors.user);
  const totalTeam = teams.length;

  return (
    <>
      <div className="py-1 text-secondary">{name}</div>
      <MessageTooltip
        placement="right"
        messageId="UserPrefs.Teams"
        messageValues={{ teams: teams.join('\n') }}
      >
        <div className="badge bg-info badge-lg badge-prepend-square">{totalTeam} teams</div>
      </MessageTooltip>
    </>
  );
}
