import { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { getRouteApi, useRouter } from '@tanstack/react-router';
import pick from 'just-pick';

import {
  useGetRefsTradingToolTemplatesQuery,
  type TradingToolTemplate,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import {
  useDeleteRecipientMutation,
  useGetRecipientsQuery,
  useUpdateRecipientMutation,
  type AdditionalField,
  type AdditionalFields,
  type Recipient,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { DeleteModal } from '@/components/hedger/manage/common/DeleteModal.tsx';
import { EditHeader, type TitleIds } from '@/components/hedger/manage/common/EditHeader.tsx';
import { EditRecipientGrid } from '@/components/hedger/manage/recipients/EditRecipientGrid.tsx';
import {
  EditRecipientInputControls,
  type RecipientData,
} from '@/components/hedger/manage/recipients/EditRecipientInputControls.tsx';
import { safeParseNumber } from '@/utils/libs/safeParseNumber.ts';
import { isQueryHookResultReady } from '@/utils/rtk/predicates.ts';

const recipientTitleIds: TitleIds = {
  Title: 'Hedger.ScenariosModal.EditRecipient.Title',
  Delete: 'Hedger.ScenariosModal.EditRecipient.Delete',
};

interface EditRecipientFrameProps {
  recipient: Recipient;
  updateRecipient: (item: Recipient) => {
    unwrap: () => Promise<Recipient>;
  };
  deleteRecipient: (item: Pick<Recipient, 'recipientId'>) => {
    unwrap: () => Promise<Recipient>;
  };
  initTradingToolTemplate: TradingToolTemplate;
  tradingToolTemplates: TradingToolTemplate[];
  closeRecipient: () => void;
}

const routeApi = getRouteApi('/hedger/recipients/edit/$recipientId');

export function EditRecipient(): JSX.Element {
  const { recipientId: recipientIdStr } = routeApi.useParams();
  const { navigate } = useRouter();

  const getRecipientsResult = useGetRecipientsQuery();
  const getRefsResult = useGetRefsTradingToolTemplatesQuery();

  const [deleteRecipient, deleteRecipientResult] = useDeleteRecipientMutation();
  const [updateRecipient, updateRecipientResult] = useUpdateRecipientMutation();

  if (
    updateRecipientResult.isLoading ||
    deleteRecipientResult.isLoading ||
    !isQueryHookResultReady(getRecipientsResult) ||
    !isQueryHookResultReady(getRefsResult)
  ) {
    return <Loader />;
  }

  if (getRecipientsResult.isError) {
    return <FetchErrorView error={getRecipientsResult.error} />;
  }
  if (getRefsResult.isError) {
    return <FetchErrorView error={getRefsResult.error} />;
  }

  const recipientToEdit = getRecipientsResult.data.recipients.find(
    recipient => recipient.recipientId === safeParseNumber(recipientIdStr),
  );
  if (recipientToEdit === undefined) {
    return <ErrorPanel> No Scenario with id {recipientIdStr} </ErrorPanel>;
  }

  const tradingToolTemplate = getRefsResult.data?.tradingToolTemplates.find(
    tradingToolTemplate =>
      tradingToolTemplate.tradingToolTemplateId === recipientToEdit?.tradingToolTemplateId,
  );
  if (tradingToolTemplate === undefined) {
    return (
      <ErrorPanel>The selected recipient has no appropriate Trading Tool Template </ErrorPanel>
    );
  }

  const currentRecipient =
    updateRecipientResult.isError && updateRecipientResult.originalArgs != null
      ? updateRecipientResult.originalArgs
      : recipientToEdit;
  const closeRecipient = () => navigate({ to: '/hedger/recipients' });

  const tradingToolTemplates = getRefsResult.data.tradingToolTemplates;
  return (
    <EditRecipientFrame
      recipient={currentRecipient}
      initTradingToolTemplate={tradingToolTemplate}
      tradingToolTemplates={tradingToolTemplates}
      deleteRecipient={deleteRecipient}
      updateRecipient={updateRecipient}
      closeRecipient={closeRecipient}
    />
  );
}

function EditRecipientFrame(editRecipientFrameProps: EditRecipientFrameProps) {
  const {
    recipient,
    initTradingToolTemplate,
    tradingToolTemplates,
    updateRecipient,
    deleteRecipient,
    closeRecipient,
  } = editRecipientFrameProps;

  const [recipientData, setRecipientData] = useState<RecipientData>(
    pick(recipient, ['recipientLabel', 'tradingToolTemplateId', 'userId', 'team']),
  );
  const [recipientAdditionalFields, setAdditionalFields] = useState<AdditionalFields>(
    recipient.additionalFields,
  );
  const currentTradingToolTemplate =
    tradingToolTemplates.find(
      element => element.tradingToolTemplateId === recipientData.tradingToolTemplateId,
    ) ?? initTradingToolTemplate;

  const deleteModal = useModal(DeleteModal);

  const disableDelete = recipient.sharedWithScenarios.length > 0;

  async function onSaveClick() {
    const currentRecipient = {
      ...recipient,
      ...recipientData,
      additionalFields: recipientAdditionalFields,
    };
    updateRecipient(currentRecipient)
      .unwrap()
      .then(closeRecipient)
      .catch(err => handleHedgerError('Error while saving recipient', err));
  }

  const onDeleteClick = () => {
    const onConfirmClick = async () => {
      deleteRecipient({ recipientId: recipient.recipientId })
        .unwrap()
        .then(closeRecipient)
        .catch(err => handleHedgerError('Error while deleting recipient', err));
    };
    deleteModal.show({
      onConfirmClick,
      type: 'Recipient',
    });
  };

  const updateAdditionalField = (key: string, additionalField: AdditionalField) => {
    setAdditionalFields(prevState => ({
      ...prevState,
      [key]: additionalField,
    }));
  };

  return (
    <div className="d-flex flex-column mx-auto w-75 gap-3 flex-grow-1 mb-3">
      <div className="d-flex flex-column flex-grow-1 bg-lvl2 gap-3 p-3 border border-info">
        <EditHeader
          titleIds={recipientTitleIds}
          onCancelClick={closeRecipient}
          onSaveClick={onSaveClick}
          onDeleteClick={onDeleteClick}
          disableDelete={disableDelete}
        />
        <EditRecipientInputControls
          id={recipient.recipientId.toString()}
          recipientData={recipientData}
          onRecipientDataUpdate={setRecipientData}
          tradingToolTemplate={currentTradingToolTemplate}
          tradingToolTemplates={tradingToolTemplates}
        />
        <EditRecipientGrid
          additionalFields={recipientAdditionalFields}
          updateAdditionalField={updateAdditionalField}
          tradingToolTemplate={currentTradingToolTemplate}
        />
      </div>
    </div>
  );
}
